interface DashboardConfig {
  // backend url
  url: string;
  // engine url
  engine: string;
  // domain url
  domain: string;
}

type DashboardEnvironment =
  | "assesshub"
  | "development"
  | "staging"
  | "Local"
  | "Porter"
  | "Production";

const config: {
  assesshub: DashboardConfig;
  development: DashboardConfig;
  staging: DashboardConfig;
  Production: DashboardConfig;
  Local: DashboardConfig;
  Porter: DashboardConfig;
} = {
  Local: {
    url: "http://localhost:10000",
    engine: "http://localhost:8000",
    domain: "http://localhost:3000",
  },
  development: {
    url: "https://dashboard-backend.dev.workhack.ai",
    engine: "https://engine.dev.workhack.ai",
    domain: "https://dashboard.dev.workhack.ai",
  },
  staging: {
    url: "https://dashboard-backend.staging.workhack.ai",
    engine: "https://engine.staging.workhack.ai",
    domain: "https://dashboard.staging.workhack.ai",
  },
  Production: {
    url: "https://dashboard-api.workhack.io",
    engine: "https://engine.workhack.io",
    domain: "https://dashboard.workhack.io",
  },
  assesshub: {
    url: "https://dashboard-api.workhack.io/assesshub",
    engine: "https://engine.workhack.io",
    domain: "https://dashboard.assesshub.com",
  },
  Porter: {
    url: "https://dashboard-backend.porter.workhack.ai",
    engine: "https://engine.porter.workhack.ai",
    domain: "https://dashboard.porter.workhack.ai",
  },
};

const environment: DashboardEnvironment = (process.env.REACT_APP_ENVIRONMENT ??
  "development") as DashboardEnvironment;

export default config[environment];
